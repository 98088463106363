@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SteradianRegular";
  src: url("fonts/SteradianRegular.woff2") format("woff2"),
    url("fonts/SteradianRegular.woff") format("woff");
}

@font-face {
  font-family: "SteradianMedium";
  src: url("fonts/SteradianMedium.woff2") format("woff2"),
    url("fonts/SteradianMedium.woff") format("woff");
}

@layer base {
  body {
    @apply font-primary text-primary;
  }
}

.swiper {
  padding-bottom: 20px !important;
}

.swiper-slide {
  background: none !important;
  align-items: flex-start !important;
}

.swiper-wrapper {
  padding-bottom: 10px;
  width: 100%;
}

.swiper-pagination {
  position: relative !important;
  top: 15px !important
}

.videoSwiper .swiper-wrapper .swiper-slide {
  height: 281px !important;
}
